.card-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-video {
  width: 500px;
  height: 400px;
}
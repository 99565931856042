@import 'antd/dist/antd.variable.min.css';

@font-face {
  font-family: 'Raleway-Black';
  src: local('Raleway-Black'), url(./fonts/Raleway-Black.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url(./fonts/Raleway-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-ExtraBold';
  src: local('Raleway-ExtraBold'), url(./fonts/Raleway-ExtraBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Raleway-ExtraLight';
  src: local('Raleway-ExtraLight'), url(./fonts/Raleway-ExtraLight.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'), url(./fonts/Raleway-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'), url(./fonts/Raleway-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url(./fonts/Raleway-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'), url(./fonts/Raleway-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Thin';
  src: local('Raleway-Thin'), url(./fonts/Raleway-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'CormorantGaramond-Regular';
  src: local('CormorantGaramond-Regular'), url(./fonts/CormorantGaramond-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'CormorantGaramond-Medium';
  src: local('CormorantGaramond-Medium'), url(./fonts/CormorantGaramond-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'CormorantGaramond-MediumItalic';
  src: local('CormorantGaramond-MediumItalic'), url(./fonts/CormorantGaramond-MediumItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./fonts/Gilroy-ExtraBold.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: 'Raleway-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px;
}

.App {
  text-align: center;
}

#root {
  height: 100%;
}

.ant-message {
  z-index: 9999 !important;
}

.ant-layout {
  height: 100%;
}

.ant-avatar>img {
  height: unset;
}

/* .ant-table-column-title {
  user-select: none;
}
.ant-table-cell {
  user-select: none;
} */

body {
  background: #FAFAFA;
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.logo {
  background-color: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
}

.landing-page {
  /* background-color: white; */
}

.landing-page-header {
  padding-top: 10px !important;
  background-color: white !important;
}

.landing-page-module {
  min-height: 230px;
  border: 2px solid #EAF0F2;
  padding: 30px 50px;
  padding-left: 120px;
}

.landing-page-module {
  min-height: 230px;
  border: 2px solid #EAF0F2;
  padding: 30px 50px;
  padding-left: 120px;
  box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
  transition: ease-in-out .3s;
}

.landing-page-module:hover {
  top: calc( 50% - 2px );
  border: 1px solid rgba(#F2F4F8, 0);
  box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 5px rgba(0,0,0,.1);
}
  
.landing-page-module:active {
  top: 50%;
  box-shadow: 0 5px 10px rgba(0,0,0,.05), 0 0 2px rgba(0,0,0,.1);
  transition: ease-in-out .1s;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #EAF0F2;
}

#components-layout-demo-side .logo {
  height: 32px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.space-full-width {
  width: 100%;
  overflow: scroll;
}

.ant-space-item {
  /* overflow: scroll !important; */
}

.ant-layout-content {
  overflow: hidden;
  position: relative;
}

.firebase-emulator-warning {
  top: 0;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
}

.display-none {
  display: none;
}

.arrow-icon {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.ant-row.ant-form-item {
  margin-bottom: 24px;
}

.ant-layout-header {
  background-color: #fff;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.03);
}

.sider-container {
  height: calc(100% - 64px);
  box-shadow: 5px 7px 10px rgba(0,0,0,0.03);
}


.slick-slide > div > div {
  height: 100%;
  user-select: none;
}

.slick-slide > div {
  height: 100%;
}

.hide-scroll { -ms-overflow-style: none; }
.hide-scroll { overflow: -moz-scrollbars-none; }
.hide-scroll::-webkit-scrollbar { width: 0 !important }

.carousel-inner-div {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-list {
  height: 100%;
}

.ant-carousel {
  height: 100%;
}

.ant-modal-body {
  height: 100%;
}

.recorder {
    transition: all 0.1s ease-out;
    z-index: 10;
    position: absolute;
    cursor: pointer;
    width: 60px;
    height: 60px;
    align-self: flex-end;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px solid white;
    margin-bottom: 25px;
    opacity: 0.5;
    pointer-events: none;
}
  
.recorder-active {
  opacity: 1;
  pointer-events: all;
}

.recorder-inner {
  transition: all 0.1s ease-out;
  width: 47px;
  height: 47px;
  border-radius: 1000px;
  background-color: #cf1322;
}

.recorder-inner-active {
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.ant-table-cell.ant-table-selection-column {
  padding-left: 15px;
}

.ant-modal-content {
  height: 100%;
  /* background-color: transparent; */
}

.ant-layout-sider-zero-width-trigger {
  top: 10px;
  right: -50px;
  border-radius: 0px;
}

.ant-table-body {
  overflow: hidden scroll;
}

.ant-table-tbody>tr>td {
  vertical-align: middle;
}

.ant-page-header.has-footer {
  padding: 0;
}

.ant-page-header-heading {
  padding: 16px 24px;
}

.ant-page-header-content {
  padding: 0 24px;
}

.ant-tabs-nav {
  padding: 0 24px 12px 24px;
}

.ant-descriptions-item-label {
  font-style: bold !important;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  margin-right: 24px;
}

.ant-col.ant-col-24.ant-form-item-label {
  padding-bottom: 0;
}

.ant-descriptions-header {
  margin: 0;
}

.demo-option-label-item > span {
  margin-right: 6px;
}

img.ant-upload-list-item-image {
  object-fit: cover;
}

.profile-photo {
  width: 200px;
  object-fit: cover;
}

.react-pdf__Document {
  align-self: center;
}

.block-quote {
  border-radius: 4px;
  background: rgba(125, 125, 125, 0.1);
  border-left: 10px solid rgba(125, 125, 125, 0.2);
  padding: 20px;
  quotes: "\201C""\201D""\2018""\2019";
}

.block-quote:before {
  color: rgba(125, 125, 125, 0.2);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.gradientback{
  position:absolute;
  z-index:2;
  right:0; bottom:0; left:0;
  height:30px; /* adjust it to your needs */
  background: url(data:image/svg+xml;base64,alotofcodehere);
  background: -moz-linear-gradient(top,  rgba(240,242,245,0) 0%, rgba(240,242,245,1) 70%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(240,242,245,0)), color-stop(70%,rgba(240,242,245,1)));
  background: -webkit-linear-gradient(top,  rgba(240,242,245,0) 0%,rgba(240,242,245,1) 70%);
  background: -o-linear-gradient(top,  rgba(240,242,245,0) 0%,rgba(240,242,245,1) 70%);
  background: -ms-linear-gradient(top,  rgba(240,242,245,0) 0%,rgba(240,242,245,1) 70%);
  background: linear-gradient(to bottom,  rgba(240,242,245,0) 0%,rgba(240,242,245,1) 70%);
}
/* 
.ant-layout-sider-children {
  background-color: #fff;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark {
  background-color: #fff;
} */

#landing-page-gradient {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  width: 100%;
  height: 100vh;
  /* background-size: cover; */
  color: white;
}

.ant-card {
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.downArrow{
	position: absolute;
	bottom: 0;
	left: 50;
}
.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.ant-popover.ant-popover-placement-bottomRight {
  margin-left: 26px !important;
}

.ant-page-header-footer {
  margin-top: 0;
}

.wrapper {
  margin: 50px 0;
  transition: all .3s ease-in-out;
  width: 300px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateZ(0);
}

.inner-book {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 2000px;
}

.inner-book:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 0;
  bottom: 14px;
  transform: rotateY(-20deg) translateZ(-20px);
  z-index: 0;
  box-shadow: 10px 12px 20px 13px rgba(0, 0, 0, 0.3);
}

.inner-book .img {
  transform: rotateY(-20deg);
  width: 100%;
  z-index: 5;
  clear: both;
  height: 100%;
  background: #ddd;
}

.inner-book img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
}

.inner-book .img:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.page {
  width: calc(100% - 20px);
  height: calc(100% - 2px);
  position: absolute;
  box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2),
    inset -1px 0px 1px rgba(150, 150, 150, 0.1);
  border-radius: 0px 3px 3px 0px;
  transform: rotateY(-20deg) translateZ(-5px);
  right: -3px;
  z-index: 4;
  background: white;
}

.page-2 {
  height: calc(100% - 4px);
  right: -6px;
  z-index: 3;
}

.page-3 {
  height: calc(100% - 6px);
  right: -9px;
  z-index: 2;
}

.page-4 {
  height: calc(100% - 8px);
  right: -12px;
  z-index: 1;
}

.page-5 {
  height: calc(100% - 10px);
  right: -15px;
  z-index: 0;
}


.ant-menu-item {
  height: unset !important;
}

.video-js.vjs-playing .vjs-tech {
  pointer-events: none !important;
}

.ant-card-body {
  overflow: hidden;
}

.ant-card-head {
  /* height: 60px; */
}

.react-joyride__beacon {
  display: none !important;
}

.calendly-overlay {
  background-color: rgba(31,31,31,0.9) !important;
}

.calendly-button {
  list-style: none !important;
  box-sizing: inherit !important;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  user-select: none !important;
  margin: 0px !important;
  padding: 0px !important;
  font: inherit !important;
  vertical-align: baseline !important;
  text-decoration: none !important;
  position: relative !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  text-align: center !important;
  border: none !important;
  outline: none !important;
  transition: all 0.2s linear 0s !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 107, 255) !important;
  opacity: 1 !important;
  height: 48px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-radius: 40px !important;
  font-family: Gilroy-ExtraBold !important;
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  line-height: 1 !important;
}

.ant-modal-title {
  font-weight: bold;
  font-size: 19px;
  width: 100%;
}

.ant-card-head-title {
  font-weight: bold;
  font-size: 19px;
  width: 100%;
  white-space: pre-wrap;
}

.calendly-event-container {
  cursor: pointer;
  -webkit-text-size-adjust: 100%;
  --fc-daygrid-event-dot-width: 8px;
  color: var(--text-color,rgb(26,26,26));
  font-size: 16px;
  font-family: proxima nova,sans-serif;
  line-height: 1.5;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  min-width: 308px;
  position: relative;
  margin: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid var(--text-color-level3,rgba(26,26,26,0.1));
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
}

.calendly-event-container:hover {
  top: -2px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 15%);
}

.calendly-event-border-top {
  height: 6px;
  margin-top: -1px;
  width: 100%;
  margin-right: -1px;
  margin-left: -1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.calendly-event-title {
  -webkit-text-size-adjust: 100%;
  --fc-daygrid-event-dot-width: 8px;
  font-family: proxima nova,sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  margin: 0;
  color: var(--text-color,rgb(26,26,26));
  font-weight: 400;
  line-height: 1.4;
  box-sizing: inherit;
  font-size: 20px;
}

.calendly-event-subtitle {
  -webkit-text-size-adjust: 100%;
  --fc-daygrid-event-dot-width: 8px;
  font-family: proxima nova,sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  box-sizing: inherit;
  color: var(--text-color-level2,rgba(26,26,26,0.6));
}

.calendly-event-link {
  -webkit-text-size-adjust: 100%;
  --fc-daygrid-event-dot-width: 8px;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font: inherit;
  background-color: transparent;
  border: none;
  outline-color: #5593ff;
  outline-offset: 4px;
  cursor: pointer;
  color: var(--primary-color,rgb(0,105,255));
  text-decoration: none;
  box-sizing: inherit;
  z-index: 1;
  margin-top: 8px;
  text-align: left;
  pointer-events: auto;
}

.calendly-checkbox {
  -webkit-text-size-adjust: 100%;
  --fc-daygrid-event-dot-width: 8px;
  font-size: 16px;
  font-family: proxima nova,sans-serif;
  line-height: 1.5;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  color: var(--primary-color,rgb(0,105,255));
  position: relative;
  width: 15px;
  height: 15px;
  background-color: var(--container-background-color,#ffffff);
  border-radius: 13.333%;
}
.card-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-media {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 16px;
}
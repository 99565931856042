.card-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.module-card-image {
  width: 100px;
  height: 100px;
}

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 350ms ease-out, transform 350ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 200ms ease-out, transform 350ms ease;
}

.quarter-with-sider {
  padding: 24px;
  overflow-x: hidden;
  position: absolute;
  top: 64px;
  bottom: 0;
  /* height: calc(100% - 160px); */
  width: 100%;
}

.ant-layout-sider-children {
  background-color: #fff;
}

/* .ant-card-hoverable:hover {
  background-color: rgba(31, 53, 51, 0.2);
  transition: background-color 200ms ease-out;
} */